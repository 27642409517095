import querystring from 'querystring';

import { ImportResultDTO, PatchObject } from '../commonTypes';
import { apiGet, apiPatch, apiPost, apiPostCSV } from '../helpers';

import {
  ParkingRightDTO,
  ParkingRightSearchResultDTO,
  ParkingRightWithVersionsDTO,
} from './types';

const BASE_URL = '/api/proxy/tv/api/cities/{cityId}/tickets/v1';
const BASE_PRV_URL = '/api/proxy/tv/api/cities/{cityId}/tickets/v1/admin';
const BASE_BO_URL = '/api/prv/v0/city/{cityId}/tickets';

export async function searchParkingRightsOrDryRunControl(
  filterParam: any
): Promise<ParkingRightSearchResultDTO> {
  return apiPost<ParkingRightSearchResultDTO>(
    `${BASE_BO_URL}/search-or-dry-run-control`,
    filterParam
  );
}

export async function searchParkingRights(
  filterParam: any
): Promise<ParkingRightSearchResultDTO> {
  return apiPost<ParkingRightSearchResultDTO>(
    `${BASE_PRV_URL}/search`,
    filterParam
  );
}

export async function fetchParkingRight(
  ticketId: string
): Promise<ParkingRightDTO> {
  return apiGet<ParkingRightDTO>(`${BASE_PRV_URL}/${ticketId}`);
}

export async function fetchParkingRightWithVersion(
  ticketId: string
): Promise<ParkingRightWithVersionsDTO> {
  return apiGet<ParkingRightWithVersionsDTO>(
    `${BASE_PRV_URL}/${ticketId}/withVersions`
  );
}

export async function importParkingRighsFromCSV(
  file: File
): Promise<ImportResultDTO> {
  return apiPostCSV<ImportResultDTO>(`${BASE_BO_URL}/import`, file);
}

export function getExport(
  filterParam: Record<string, any>,
  exportParam: any
): Promise<void> {
  return apiGet<void>(
    `${BASE_BO_URL}/export?${querystring.stringify({
      ...filterParam,
      ...exportParam,
    })}`
  );
}

export async function patch(
  ticketId: string,
  patchObject: Array<PatchObject<unknown>>
): Promise<ParkingRightDTO> {
  return apiPatch<ParkingRightDTO>(`${BASE_URL}/${ticketId}`, patchObject);
}
